<!--
  PACKAGE_NAME : src/pages/setting/history/excel-lookup-history.vue
  FILE_NAME : excel-lookup-history
  AUTHOR : sskim
  DATE : 2023-05-04
  DESCRIPTION : 보고서 이력
-->
<template>
  <div class="container">
    <div class="page-sub-box">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <Search
          codeKey="search_type_member"
          :searchObj="searchType.obj"
          @change-search-type="$_changeSearchType"
          @onSearchClick="$_searchData($event, 'search_type_member')"
        >
          <template v-slot:before>
            <div class="ui-datepicker-item">
              <DxDateBox
                :styling-mode="config.stylingMode"
                :width="120"
                v-model="searchType.customTypes.dayStart"
                type="date"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                @value-changed="onChangeDayStart"
              >
              </DxDateBox>
            </div>
            <div class="ui-datepicker period">
              <span class="dash">~</span>
            </div>

            <div class="ui-datepicker-item">
              <DxDateBox
                :styling-mode="config.stylingMode"
                :width="120"
                v-model="searchType.customTypes.dayEnd"
                type="date"
                display-format="yyyy.MM.dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                @value-changed="onChangeDayEnd"
              >
              </DxDateBox>
            </div>
          </template>
        </Search>
        <esp-dx-data-grid :dataGrid="grid.historyData" ref="historyData" @exporting="onExporting" />
      </div>
    </div>

    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"> </component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>

    <DxPopup
      :show-title="true"
      :title="targetModal.initData ? targetModal.initData.title : null"
      :min-width="targetModal.initData ? targetModal.initData.width : null"
      :width="targetModal.initData ? targetModal.initData.width : null"
      :min-height="targetModal.initData ? targetModal.initData.height : null"
      :height="targetModal.initData ? targetModal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="targetModal.isOpened"
      @hiding="isOpenTargetModal(false)"
    >
      <template #content>
        <div>
          <component
            :is="targetModal.currentComponent"
            :contentData="targetModal.contentData"
            :contentHeader="targetModal.contentHeader"
            :totalCount="targetModal.totalCount"
            v-model="targetModal.contentData"
          >
          </component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          targetModal.initData.hasOwnProperty('buttons')
            ? targetModal.initData.buttons.hasOwnProperty('cancel')
              ? targetModal.initData.buttons.hasOwnProperty('cancel')
              : !targetModal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: targetModal.initData.hasOwnProperty('buttons')
            ? targetModal.initData.buttons.hasOwnProperty('cancel')
              ? targetModal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenTargetModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>
<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxCheckBox } from 'devextreme-vue/check-box';

  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { DxSelection, DxFilterRow, DxScrolling, DxColumn } from 'devextreme-vue/data-grid';
  import Search from '@/components/common/search.vue';
  import { isSuccess, downloadFile, getResData } from '@/plugins/common-lib';
  import ExcelHistoryDetail from '@/pages/esp/history/excel-history-detail.vue';
  import ExcelHistoryTargetDetail from '@/pages/esp/history/excel-history-target-detail.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxTextBox } from 'devextreme-vue/text-box';

  import { exportDataGrid } from 'devextreme/excel_exporter';
  import ExcelJS from 'exceljs';
  import moment from 'moment/moment';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxDateBox,
      DxPopup,
      DxToolbarItem,
      DxDropDownBox,
      DxSelection,
      DxFilterRow,
      DxScrolling,
      DxColumn,
      DxCheckBox,
      Search,
      ExcelHistoryDetail,
      ExcelHistoryTargetDetail,
      DxSelectBox,
      DxTextBox,
    },
    props: {},
    watch: {},
    data() {
      //let vm = this;
      return {
        menu: {
          depth1: [],
          depth2: [],
          depth3: [],
        },
        downloadStatus: 0,
        downloadPercentage: 0,
        menuDataSource: null,
        config: {
          pageSetting: {
            config: {},
            // 페이지 데이터에 입력하고 DEV 를 붙이면 localhost
            apiActionNm: {
              selectHistory: 'REPORT_HISTORY_LIST', // GET
              selectDetail: 'REPORT_DOWNLOAD_LIST_ALL_DEV', // GET
              checkExcelDownload: 'REPORT_HISTORY_UPDATE_CNT_DEV', // PUT
              getExcelFile: 'REPORT_DOWNLODA_EXCEL_DEV', // POST
            },
          },
          stylingMode: 'outlined', //outlined, underlined, filled
        },
        modal: {
          isOpened: false,
          currentComponent: 'null',
          initData: {
            name: 'viewContent',
            title: '다운로드 전체 이력',
            buttons: {
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '550',
          },
          contentData: null,
        },
        targetModal: {
          isOpened: false,
          currentComponent: 'null',
          initData: {
            name: 'viewContent',
            title: '보고서 조회 대상',
            buttons: {
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '700',
          },
          contentHeader: null,
          contentData: null,
          totalCount: 0,
        },
        searchType: {
          obj: {
            searchTexts: {
              width: 200,
              height: 30,
            },
          },
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substr(0, 10),
            dayEnd: new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10),
            reportSearchPeriod: null,
            reportId: null,
            workSection: null,
          },
          paramsData: null,
        },
        grid: {
          historyData: {
            refName: 'excelHistoryRefName',
            allowColumnResizing: true, //컬럼 사이즈 허용
            showBorders: false, //border 유무
            showColumnHeaders: true, //컬럼 헤더 유무
            showColumnLines: false, //컬럼 세로선 유무
            showRowLines: true, //컬럼 가로선 유무
            rowAlternationEnabled: false,
            repaintChangesOnly: true,
            hoverStateEnabled: true,
            dataSource: [],
            // width:'200',     // 주석처리시 100%
            //height: 'calc(100vh - 440px)', // 주석처리시 100%
            showActionButtons: {
              //툴바 버튼 / 순서(sortIndex) 설정 default : [ 복사: 10, 조회 20, 추가: 30, 저장: 40, 취소: 50, 삭제: 60, 커스텀 버튼: 70번대(71, 72, 73..), 엑셀다운로드: 100 ]
              excel: true,
              save: false,
              cancel: false,
              customButtons: [
                //커스텀 버튼
                {
                  widget: 'dxCheckBox',
                  sortIndex: 110, //순서 설정 가능
                  options: {
                    elementAttr: { id: 'filterMyData', class: 'excelBtn' },
                    text: '나의 다운로드 이력만 보기',
                    width: 200,
                    value: false,
                    onValueChanged: this.onMyData,
                  },
                  location: 'before',
                },
              ],
            },
            customEvent: {
              exporting: true,
            },
            columnChooser: {
              enabled: false, // 컬럼 Chooser 버튼 사용유무
            },
            loadPanel: {
              enabled: false, // 로딩바 표시 유무
            },
            sorting: {
              mode: 'multiple', // single multiple
            },
            // scrolling: {
            // 	// 미사용시 주석처리
            // 	mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
            // },
            remoteOperations: {
              filtering: false,
              sorting: true,
              grouping: false,
              paging: true,
            },
            paging: {
              // scrolling 미사용시만 적용됨
              enabled: true,
              pageSize: 10,
              pageIndex: 0, // 시작페이지
            },
            pager: {
              visible: true, //페이저 표시 여부
              showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
              allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
              displayMode: 'compact', //표시 모드 : ['full', 'compact']
              showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
              showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
            },
            filterRow: {
              visible: true,
              // operationDescriptions: {
              // 	contains: '포함',
              // },
            },
            headerFilter: {
              visible: true,
            },
            editing: {
              allowUpdating: false,
              allowDeleting: false,
              allowAdding: false,
              changes: [],
              mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
              //startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
              //selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
            },
            selecting: {
              mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
              selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
              showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            },
            excel: {
              title: '보고서 이력',
            },
          },
        },
        weekdayDict: [
          { id: 'mon', alt: '월', value: '2', checked: true },
          { id: 'tue', alt: '화', value: '3', checked: true },
          { id: 'wed', alt: '수', value: '4', checked: true },
          { id: 'thu', alt: '목', value: '5', checked: true },
          { id: 'fri', alt: '금', value: '6', checked: true },
          { id: 'sut', alt: '토', value: '7', checked: true },
          { id: 'sun', alt: '일', value: '1', checked: true },
          { id: 'holi', alt: '공휴일', value: 'Y', checked: true },
        ],
        excelDict: [
          { alt: '조회', value: 0 },
          { alt: '일반', value: 1 },
          { alt: '일괄', value: 2 },
          { alt: '대상', value: 3 },
        ],
      };
    },
    computed: {
      getApiActionNm() {
        return this.config.pageSetting.config ? this.config.pageSetting.config.apiActionNm : null;
      },
      getWorkSection() {
        return this.$_getCode('work_section').sort((a, b) => {
          if (a.codeValue > b.codeValue) return 1;
          if (a.codeValue < b.codeValue) return -1;
          return 0;
        });
      },
    },
    methods: {
      onUpdateCountAndDownload(option) {
        option.data.excelDownloadCnt++;
        option.component.refresh();
      },
      onMyData(event) {
        const loginId = this.$store.getters.getLoginId;
        if (event.value) {
          this.$refs.historyData.getGridInstance.filter(['loginId', '=', loginId]);
        } else {
          this.$refs.historyData.getGridInstance.clearFilter();
        }
      },
      onChangeWorkSection() {
        let workSection = this.searchType.customTypes.workSection;
        this.searchType.paramsData = { ...this.searchType.paramsData, workSection: workSection };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      onChangeDayStart() {
        let executeTmPeriod = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, executeTm: executeTmPeriod };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      onChangeDayEnd() {
        let executeTmPeriod = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, executeTm: executeTmPeriod };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      mappingMenuLevelInfo(target) {
        let menu = {};
        menu.index = target;

        const reportMenu = this.reportMenuList.find(el => el.id == menu.index);

        menu.menu1Id = reportMenu?.masterId ?? 0;
        menu.menu1Nm = reportMenu?.parent.menuTypeCode.codeNm ?? '-';

        menu.menu2Id = reportMenu?.parentId ?? 0;
        menu.menu2Nm = reportMenu?.parentNm ?? '-';

        menu.menu3Id = reportMenu?.id ?? 0;
        menu.menu3Nm = reportMenu?.menuNm ?? '-';
        return menu;
      },
      gridBoxDisplayExpr(item) {
        return item && `${item.menu3Nm}`;
      },
      setHistoryData() {
        return {
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              width: 70,
              //height: 40,
              alignment: 'center', // left center right
              visible: false,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '대메뉴',
              dataField: 'parentMenuId',
              alignment: 'center',
              width: 130,
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, option) => {
                let div = document.createElement('div');
                const menu = this.getMenuById(option.data.parentMenuId);
                const parentMenu = this.getMenuById(menu.parentId);
                div.innerHTML = parentMenu?.menuNm;
                container.append(div);
              },
            },
            {
              caption: '중메뉴',
              dataField: 'parentMenuId',
              alignment: 'center',
              width: 130,
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, option) => {
                let div = document.createElement('div');
                div.innerHTML = this.getMenuById(option.data.parentMenuId)?.menuNm;
                container.append(div);
              },
            },
            {
              caption: '소메뉴',
              dataField: 'reportMenuNm',
              alignment: 'center',
              width: 130,
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: true,
              cellTemplate: (container, option) => {
                let div = document.createElement('div');
                div.innerHTML = this.getMenuById(option.data.menuId)?.menuNm;
                container.append(div);
              },
            },
            {
              caption: '조회기간',
              dataField: 'reportSearchPeriod',
              alignment: 'center',
              width: 230,
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, option) => {
                let div = document.createElement('div');
                let value = option.data.reportSearchPeriod;

                const weekdayList = JSON.parse(option.data.reportSearchWeek);
                const holi = JSON.parse(option.data.reportSearchData).holi;
                weekdayList.push(holi);
                const weekdaysArray = weekdayList.reduce((acc, value) => {
                  const dayObj = this.weekdayDict.find(item => item.value == value);
                  if (dayObj !== undefined) acc.push(dayObj.alt);
                  return acc;
                }, []);

                if (value !== '-') {
                  const startDt = moment(value.split('~')[0]).format('YYYY-MM-DD');
                  const endDt = moment(value.split('~')[1]).format('YYYY-MM-DD');
                  const htmlArr = [];
                  htmlArr.push(`${startDt} ~ ${endDt}`); // 기간
                  htmlArr.push(weekdaysArray.join(', ')); // 요일 및 공휴일
                  htmlArr.push(option.data.reportSearchTime); // 시간
                  div.innerHTML = htmlArr.join('<br>');
                } else {
                  div.innerHTML = value;
                }
                container.append(div);
              },
            },
            {
              caption: '조회 대상',
              dataField: 'reportSearchData',
              alignment: 'center',
              width: 120,
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, option) => {
                let tag = document.createElement('div');
                try {
                  //조회대상 값
                  const { reportSearchData } = option.data;
                  const parsedData = JSON.parse(reportSearchData);
                  const parsedValue = parsedData.obj;

                  //조회대상 헤더
                  const objHeaderValue = parsedData.objHeader;
                  let objHeader = [{ caption: '조회 대상', dataField: 'name', width: 400 }];
                  if (!parsedValue) {
                    tag.innerHTML = '-';
                  } else if (parsedValue[0] === '') {
                    parsedValue = JSON.parse(parsedData[0].customObj);
                    tag = document.createElement('a');
                    tag.innerText = parsedValue.length;
                    tag.addEventListener('click', () => {
                      this.onOpenTargetPopup(parsedValue, reportSearchData, objHeaderValue);
                    });
                  } else {
                    //체크 대상
                    tag = document.createElement('a');
                    tag.innerText = parsedValue.length;
                    tag.addEventListener('click', () => {
                      this.onOpenTargetPopup(option.data.reportSearchObj, reportSearchData, objHeaderValue);
                    });
                  }
                } catch (e) {
                  tag.innerHTML = '-';
                }
                container.append(tag);
              },
            },
            {
              caption: '보고서 유형',
              dataField: 'reportSearchType',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: false,
              lookup: {
                dataSource: this.$_getCode('report_type'),
                displayExpr: 'codeNm',
                valueExpr: 'codeValue',
              },
              cellTemplate: (container, option) => {
                let div = document.createElement('div');
                let codeObj = this.$_getCode('report_type').find(code => code.codeValue == option.value);
                div.innerHTML = codeObj?.codeNm ?? '-';
                container.append(div);
              },
            },
            {
              caption: '작업 구분',
              dataField: 'workSection',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: true,
              lookup: {
                dataSource: this.$_getCode('work_section'),
                displayExpr: 'codeNm',
                valueExpr: 'codeValue',
              },
              cellTemplate: (container, option) => {
                let div = document.createElement('div');
                let codeNm = this.$_getCode('work_section').find(code => code.codeValue == option.value).codeNm;

                if (option.data.excelFl === '0') {
                  div.innerHTML = codeNm;
                } else {
                  try {
                    div.innerHTML = codeNm + '(' + this.excelDict.find(item => item.value == option.data.excelFl).alt + ')';
                  } catch (e) {
                    div.innerHTML = codeNm;
                  }
                }
                container.append(div);
              },
            },
            {
              caption: '상태',
              dataField: 'excelStatus',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              cssClass: 'statusBgColor',
              cellTemplate: (container, option) => {
                let checkValue = option.data.excelStatus;
                if (checkValue === 'complete' || checkValue === '0') {
                  checkValue = '완료';
                } else {
                  checkValue = this.downloadPercentage + '%';
                }
                container.append(checkValue);
              },
            },
            {
              caption: '엑셀 다운로드',
              dataField: 'excelFl',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, option) => {
                if (option.data.excelFl === '0') {
                  container.append('-');
                } else {
                  const { excelStatus, reportSearchData } = option.data;
                  const isLargeData = JSON.parse(reportSearchData)?.isLargeData;

                  let buttonText = '-';
                  let buttonSize = 60;
                  let buttonOnClick = null;
                  if (excelStatus === 'complete') {
                    buttonText = isLargeData ? '대용량' : '기본';
                    buttonSize = isLargeData ? 80 : 60;
                    buttonOnClick = async () => await this.onClickExcelButton(isLargeData, option);
                  }

                  if (buttonText === '-') {
                    container.append(buttonText);
                  } else {
                    container.append(
                      new DxButton({
                        propsData: {
                          text: buttonText,
                          elementAttr: { class: 'btn_XS white excel light_filled' },
                          width: buttonSize,
                          height: 30,
                          onClick: buttonOnClick,
                        },
                      }).$mount().$el,
                    );
                  }
                }
              },
            },
            {
              caption: '다운로드 횟수',
              dataField: 'excelDownloadCnt',
              alignment: 'center',
              sortOrder: 'none',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, option) => {
                let tag = document.createElement('div');
                let value = option.data.excelDownloadCnt;
                if (!value) {
                  tag.innerHTML = '-';
                } else {
                  tag = document.createElement('a');
                  tag.innerText = value;
                  tag.addEventListener('click', () => {
                    this.onOpenDownloadCountPopup(option.data.id);
                  });
                }
                container.append(tag);
              },
            },
            {
              caption: '사용자',
              dataField: 'loginNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              cellTemplate: (container, option) => {
                let tag = document.createElement('div');
                let value = `${option.data.loginNm}(${option.data.loginId})`;
                tag.innerHTML = value;
                container.append(tag);
              },
            },
            {
              caption: '실행 시간',
              dataField: 'executeTm',
              alignment: 'center',
              visible: true,
              sortOrder: 'desc',
              allowHeaderFiltering: false,
              allowFiltering: false,
              cssClass: 'tmBgColor',
              calculateCellValue: this.editETM,
            },
          ],
        };
      },
      getMenuById(id) {
        return this.$store.getters.getMenuList.find(menu => menu.id === id);
      },
      getParentMenu(id) {
        console.log('getParentMenuName:', id);
        const menuObj = this.$store.getters.getMenuList.find(menu => menu.id === id);
        this.$store.getters.getMenuList.find(menu => menuObj.parentId === id);
        if (!menuObj) return '-';
        return menuObj.menuNm || '-';
      },
      editETM(rowData) {
        return this.commonDateFormat(rowData.executeTm);
      },
      commonDateFormat(date) {
        if (!date) return '-';
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      },
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.contentData = [];
        }
      },
      isOpenTargetModal(data) {
        this.targetModal.isOpened = data;
        if (!data) {
          this.targetModal.currentComponent = null;
          this.targetModal.contentHeader = [];
          this.targetModal.contentData = [];
          this.targetModal.totalCount = 0;
        }
      },
      async onCheckExcelDownload(option, isLargeData) {
        if (this.checkPrepareDownload(option, isLargeData)) {
          return await this.onDownLoadExcelFile(option);
        }
        this.$_Msg('다운로드 실패');
      },
      async onDownLoadExcelFile(option) {
        let payload = {
          actionname: 'REPORT_HISTORY_FILE_DOWNLOAD',
          path: `/${option.data.id}`,
          loading: true,
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.downloadPercentage = percentCompleted;
          },
        };

        let res = await this.CALL_REPORT_API(payload);
        if (res.status == '200') {
          if (!res.data || res.data?.header?.resCode == 'fail') {
            this.$_Msg(`다운로드 실패 \n ${res.data?.header?.resMsg ? `Cause :  ${res.data.header.resMsg}` : ''}`);
          } else {
            await downloadFile(res);
            this.downloadStatus = 1;
            await this.selectDataList();
          }
        } else {
          this.$_Msg('다운로드 실패');
        }
      },
      async onClickExcelButton(isLargeData, option) {
        if (isLargeData) {
          await this.onCheckExcelDownload(option, isLargeData); // 대용량
        } else {
          await this.onCheckDefaultExcel(option, isLargeData); // 일반
        }

        if (this.downloadStatus === 1) {
          this.$refs.historyData.getGridInstance.cellValue(option.rowIndex, 'excelDownloadCnt', 'complete');
          this.downloadStatus = 0;
        }
      },
      /**
       * 엑셀 다운로드(엑셀다운: 일반, 기본)
       * @param option
       * @param isLargeData
       * @returns {Promise<void>}
       */
      async onCheckDefaultExcel(option, isLargeData) {
        if (this.checkPrepareDownload(option, isLargeData)) {
          await this.onDefaultDownLoadExcelFile(option);
          return this.selectDataList();
        }
        this.$_Msg('다운로드 실패');
      },
      async checkPrepareDownload(option, isLargeData) {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_HISTORY_UPDATE_CNT',
          data: { id: option.data.id, excelFl: option.data.excelFl, isLargeData },
          loading: true,
        });

        if (isSuccess(res)) {
          return true;
        }
        return false;
      },
      async onDefaultDownLoadExcelFile(option) {
        const params = JSON.parse(option.data.reportSearchData);
        params.historyId = option.data.id;
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_EXCEL_RE_DOWNLOAD',
          data: { data: params },
          loading: true,
          responseType: 'arraybuffer',
        });

        if (res.status == '200' && res.data) {
          await downloadFile(res);
          await this.selectDataList();
        } else {
          this.$_Msg('다운로드 실패');
        }
      },

      async onOpenDownloadCountPopup(value) {
        try {
          await this.selectDetailInfo(value);
          this.modal.currentComponent = 'ExcelHistoryDetail';
          this.isOpenModal(true);
        } catch (e) {
          this.$_Msg('다운로드 상세내역 조회에 실패하였습니다.', { icon: 'warning' });
          this.isOpenModal(false);
        }
      },

      async onOpenTargetPopup(reportSearchObj, reportSearchData, objHeaderValue) {
        try {
          await this.selectTargetDetail(reportSearchObj, reportSearchData, objHeaderValue);
          this.targetModal.currentComponent = 'ExcelHistoryTargetDetail';
          this.isOpenTargetModal(true);
        } catch (err) {
          console.log(err);
          this.$_Msg('조회 대상 조회에 실패하였습니다.', { icon: 'warning' });
          this.isOpenTargetModal(false);
        }
      },

      async selectDetailInfo(value) {
        this.modal.contentData = [];
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_HISTORY_DETAIL',
          path: `/${value}`,
          loading: true,
        });
        if (isSuccess(res)) {
          this.modal.contentData = getResData(res);
        } else {
          throw 'Data Loading Error';
        }
      },

      async selectTargetDetail(reportSearchObj, reportSearchData, objHeaderValue) {
        this.targetModal.contentHeader = [{ caption: '조회 대상', dataField: 'name', width: 400 }];
        this.targetModal.contentData = [];
        this.targetModal.totalCount = 0;
        // 개별
        if (Array.isArray(reportSearchObj)) {
          let objHeader = null;
          this.targetModal.contentData = reportSearchObj.map((item, index) => {
            let value = [];
            if (objHeader === null) {
              //TODO: 헤더 정상적으로 넘어가는 로직 정리되면 해당 if 지울 예정
              value = Object.values(item);
            } else {
              value = objHeader
                .map((header, i) => {
                  const itemData = item[`item${i + 1}`];
                  if (itemData) {
                    return `${header}: ${itemData}`;
                  }
                  return '';
                })
                .filter(v => v !== '');
            }
            return { key: index, name: value?.join(',   ') };
          });
          this.targetModal.totalCount = reportSearchObj.length;
          return;
        }

        //TODO: 조회대상 상세 이력을 보여주는게 맞을지 고민 필요, 그냥 JSON 형태의 파라미터를 보여주는 게 더 나을 수도 있겠다 샘각
        const checkModeTargetDataList = await this.getCheckModeTargetDataList(reportSearchObj, reportSearchData);
        this.targetModal.contentData = checkModeTargetDataList;
        this.targetModal.totalCount = checkModeTargetDataList.length;
      },
      /**
       * TODO: 로직 정리 필요
       * @description 보고서 조회대상 상세 리스트 조회 (체크모드)
       * @param {String} idList 조회대상 ID 리스트
       * @param {String} reportSearchData 조회대상 검색조건
       * @returns {Array} 조회대상 상세 리스트
       * */
      async getCheckModeTargetDataList(idList, reportSearchData) {
        const parsedValue = JSON.parse(reportSearchData);
        const objType = parsedValue.objType;
        const solution = parsedValue.solution;
        const subPath = parsedValue.subPath;
        const queryGroupList = await this.setQueryGroupList(objType, solution, subPath);
        return idList
          .slice(1, -1)
          .split(',')
          .map(item => {
            const result = queryGroupList.find(obj => obj.VALUE.toString() === item.replaceAll('"', ''));
            const name = result ? (result.NAMETREE ? result.NAMETREE.replaceAll('|', ' > ') : result.TEXT) : 'N/A';
            return { key: item, name: name };
          });
      },
      async getInputModeTargetDataList() {
        return [];
      },
      async selectDataList() {
        const vm = this;
        this.grid.historyData.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);
            if (
              vm.searchType.paramsData !== null &&
              vm.searchType.paramsData.hasOwnProperty('workSection') &&
              vm.searchType.paramsData.workSection === '0'
            ) {
              delete vm.searchType.paramsData.workSection;
            }

            let payload = {
              actionname: 'REPORT_HISTORY_LIST',
              data: { ...params, ...vm.searchType.paramsData },
              loading: true,
            };

            const res = await vm.CALL_REPORT_API(payload);
            if (isSuccess(res)) {
              let rtnData = {
                data: getResData(res),
                totalCount: res.data.header.totalCount,
              };

              vm.$refs.historyData.totalCount = rtnData.totalCount;
              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      async setQueryGroupList(objType, solution, subPath) {
        const res = await this.getReportObjectList(objType, solution, subPath);
        if (isSuccess(res)) {
          const objectList = this.$_commonlib.cloneObj(res.data.data);
          return objectList;
        } else {
          return () => {
            throw 'Data Loading Error';
          };
        }
      },
      async getReportObjectList(objType, solution, subPath) {
        return await this.asyncGetMasterQueryResult({
          name: objType,
          solution: solution,
          subPath: subPath,
          loginId: this.$store.getters.getLoginId,
        });
      },
      async asyncGetMasterQueryResult(data, useLoading = true) {
        return this.CALL_REPORT_API({
          actionname: 'REPORT_TARGET_RESULT_LIST',
          data: { data },
          loading: useLoading,
        });
      },
      onExporting(e) {
        //Grid Excel Export
        const title = this.grid.historyData.excel.title;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        //Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
        let columnsArr = [];
        this.grid.historyData.columns.forEach(d => {
          columnsArr.push({ width: this.grid.historyData.excel.cellwidth ? this.grid.historyData.excel.cellwidth : 30 });
        });
        worksheet.columns = columnsArr;

        let today = this.$_commonlib.formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');
        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          keepColumnWidths: false,
          autoFilterEnabled: this.grid.historyData.excel.autoFilterEnabled ? this.grid.historyData.excel.autoFilterEnabled : false, //자동필터 설정 여부
          topLeftCell: { row: 4, column: 1 },
          customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'header') {
              //header 영역 설정
              excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
              excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
            } else {
              //data 영역 배경색 설정
              if (excelCell.fullAddress.row % 2 === 0) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'F2F2F2' },
                  bgColor: { argb: 'F2F2F2' },
                };
              }
            }
            //Cell boader line
            const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
            excelCell.border = {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
            };
          },
        })
          .then(cellRange => {
            // title Cell
            const titleRow = worksheet.getRow(2);
            titleRow.height = 40;
            worksheet.mergeCells(2, 1, 2, this.grid.historyData.columns.length);
            titleRow.getCell(1).value = title;
            titleRow.getCell(1).font = { size: 22, bold: true };
            titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

            //Grid Header Height
            const hearderRow = worksheet.getRow(4);
            hearderRow.height = 30;
          })
          .then(async () => {
            let fileId = null;
            await workbook.xlsx.writeBuffer().then(buffer => {
              fileId = `${title}_${today}.xlsx`;
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${title}_${today}.xlsx`);
            });

            const url = this.$route.path;
            const menuObj = JSON.parse(sessionStorage.getItem('menuList')).filter(e => e.pageUrl == url);

            const menuId = menuObj[0].id;
            const parentId = menuObj[0].parentId;
            const data = { menuId, parentId, fileId };

            let payload = {
              actionname: 'EXCEL_HISTORY_UPDATE',
              data: { data: data },
              loading: false,
            };

            let res = await this.CALL_REPORT_API(payload);

            console.log('res ', res);
          });
        e.cancel = true;
      },
      async initComponent() {
        await Object.assign(this.grid.historyData, this.setHistoryData());
        await this.selectDataList();
      },
    },
    mounted() {
      //store에 있는 커스텀 검색 이력 가져오기
      this.$_getSearchHistsCustomType(this.searchType.customTypes);
      this.$_setSearchHistsCustomType(this.searchType.paramsData);
    },
    beforeCreate() {},
    created() {
      this.initComponent();
      this.$_setPageSettingConfig(); // 페이지데이터 사용시
    },
    beforeDestroy() {},
  };
</script>
<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }

  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
</style>
<style>
  .excelBtn {
    padding: 0 0 0 15px !important;
  }
</style>
